import React, {  useState } from "react";
import ZoneDate from "./zone-date";
import { format, addHours } from 'date-fns';
import moment from 'moment-timezone';




const ZoneDates = props => {
 
    // const [timezones, setTimezones] = useState(props.timezones);
    const timezones = props.timezones;
    const dateFormat = { month: 'long', day: 'numeric' }; 

    const selectedDate = props.selectedDate;

    let twoDaysAgo = new Date(selectedDate);
    twoDaysAgo.setDate(selectedDate.getDate() - 2);

    let yesterday = new Date(selectedDate);
    yesterday.setDate(selectedDate.getDate() - 1);

    let tomorrow = new Date(selectedDate);
    tomorrow.setDate(selectedDate.getDate()+ 1);

    let twoDaysAfter = new Date(selectedDate);
    twoDaysAfter.setDate(selectedDate.getDate()+ 2);

    // set the dates as array
    let dates = [twoDaysAgo.toLocaleDateString("en-US", dateFormat), yesterday.toLocaleDateString("en-US", dateFormat),  selectedDate.toLocaleDateString("en-US", dateFormat),tomorrow.toLocaleDateString("en-US", dateFormat), twoDaysAfter.toLocaleDateString("en-US", dateFormat), ];
    // Formats the time to am/pm and change 12:00 am/pm to noon/midnight
    const formatTime=(timeSelected, timezone)=>{

        const zone = timezone.utc[0];
        const isDST = moment.tz(timeSelected, zone).isDST();
        let offset = timezone.offset;
        if(isDST){
            offset =  timezone.dstOffset;
        }

        let getFormattedTime = timeSelected ? format(addHours(props.timeSelected, offset ), "hh:mm aa") :null;
        if(getFormattedTime==="12:00 a.m.") getFormattedTime="Midnight";
        else if(getFormattedTime==="12:00 p.m.") getFormattedTime="Noon";

        return getFormattedTime
    }

    const getAbbreviations=(timeSelected, timezone)=>{
        const zone = timezone.utc[0];
        const abbr = moment.tz(timeSelected, zone).zoneAbbr();

        return abbr;
    }

    // gets/calculates the offset
    const getOffset=(timeSelected, timezone)=>{
        
        const zone = timezone.utc[0] ?  timezone.utc[0] : null ;
        if(zone !== null){
            const isDST = moment.tz(timeSelected, zone).isDST();
            const offset = isDST ? timezone.dstOffset: timezone.offset;
            return offset;
        }
        else{
            return timezone.offset;
        }
       
    }

    const handleName=(timeSelected, data)=>{
       
        if(data.name){
            return data.name
        }
        else{
            const zone = data.timezone.utc[0] ?  data.timezone.utc[0] : null ;
            
            if(zone !== null){
                const isDST = moment.tz(timeSelected, zone).isDST();
                const name = isDST ? data.timezone.dstText :  data.timezone.text
                return name
            }
            else{
                 return data.timezone.text;
            }
        }
      
    }

    return (
        <div draggable="false">
            {props.timezones.map((data)=>{
                return(
                    <>  
         
                        {data.timezone ? 
                            <>
                                <div key={data.timezone.key} style={{textAlign: "left", fontSize: "medium", width: "100rem", marginLeft:"1rem"}}> 
                                    {formatTime(props.selectedDate, data.timezone)}  {handleName(props.selectedDate, data)}, ({getAbbreviations(props.selectedDate, data.timezone)}) 
                                </div>
                                <div className="zone-dates" >
                                    {dates.map((date)=>{
                                    return(
                                        <ZoneDate offset={getOffset(props.selectedDate, data.timezone)} zoneWidth={props.zoneWidth} timezone={data.timezone} date={date}></ZoneDate>
                                    )
                                
                                    })}
                        
                                </div>  
                            </>
                            : null
                        }
                     
                    </>
                )
            })}
        </div>     

    
  );
};

export default ZoneDates;
