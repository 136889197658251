import React, { useEffect, useCallback, useState } from "react";
import SearchableDropdown from "../columns/SearchableDropDown";
import { url } from '../../../src/hooks/use-form-url';
import { sortByOrder } from "./timezone-converter";
import useCopyToClipboard from "./functions/useCopyToClipboard";
import useAssembleTimezoneName from "./functions/useAssembleTimezoneName";
import { useStaticQuery } from "gatsby";

const TimezoneUrlGeneratorModal = props =>{
	
	// contains the list retrieved from json data
	const [timezoneList, setTimezoneList] = useState(props.timezoneList);
	const allTimezones = props.allTimezonesQueryEdges.map(item =>{ return item.node});
	
	// displays the custom url to be copied
	const [customUrl, setCustomUrl] = useState();

	// flag for the searchable drop down to reset its values
	const [reset, setReset] = useState(true);

	// to be passed and called to the searchable drop down component to set the reset flag to false
    const wrapperSetReset = useCallback(()=>{return setReset(false)});

	useEffect(() => {
		props.closeGeneratorModalStateSetter();
	}, [props.closeGeneratorModalStateSetter]);

	// Initialize the timezone state
	// this will only run one time
	useEffect(() => {
		setTimezoneList(props.timezoneList)
		
	});

	// update the custom url if the timezonelist changes
	useEffect(()=>{
	   updateCustomUrl();
	}, [timezoneList])

	// Call this if you want to copy to clipboard
	// ex. copyToClipboard("text to copy")
	const copyToClipboard = useCopyToClipboard();

	// assemble the timezone text + abbreviation
	const assembleTimezoneName = useAssembleTimezoneName();

	// Closes the modal
	if(!props.modalState) {
    	return null;
  	}


	// Add a blank timezone item
	const addBlank =()=>{
		const getId = timezoneList.length + 1;
		const newTimezone = timezoneList;
		newTimezone.push({id: getId, order: timezoneList.length, key:"", name: "", timezone: null});
		props.wrapperSetTimezoneList(newTimezone);
		updateCustomUrl();
	}

	// remove a timezone item when the x button is clicked
	const removeTimezone=(id)=>{
		const newTimezoneList = timezoneList.filter((item) => item.id !== id);
		props.wrapperSetTimezoneList(newTimezoneList)
		updateCustomUrl();
	}

	// handles the custom names 
	const customNameOnChange=(id, event)=>{
	
		const updateName = timezoneList.map(item => {
			
			if(item.id === id){
				item.name = event.target.value;
				return item;
			}
			else return item;
		});
		props.wrapperSetTimezoneList(updateName)
		updateCustomUrl();
	}

	// update the timezone
	const handleDropDownChange=(timezone, event)=>{
		if(event){
			timezoneList.map(item => {
				if(item.id === timezone.id){
					item.key = event.key
					item.timezone = event
				}
			})
			props.wrapperSetTimezoneList(timezoneList)
		}
		updateCustomUrl();
		
	}

	// Handles the updating of the custom url to be copied
	function updateCustomUrl(){
		if (typeof window !== 'undefined') {
			const getPathName = window.location.pathname;
			const getUrl = url;
			let timezoneUrlParam = "";
			if(timezoneList){
				timezoneList.map(item =>{
					timezoneUrlParam =`${timezoneUrlParam}&timezone=${item.key},${encodeURIComponent(item.name)}`
				})
				setCustomUrl(`${getUrl}${getPathName}?${timezoneUrlParam}` )
			}
			
		}
	}

	// moves the timezone item Up
	const moveUp =(timezone)=>{
		const id = timezone.id;
		const getOrder = timezone.order;
		if(getOrder > 0){
			
			const moveUp = getOrder - 1;
			timezoneList.map(item => {
				if(item.order === moveUp && item.id !== id){
					item.order = item.order + 1;
				}
				else if(item.id === id){
					item.order = moveUp
				}
			})
			const sorted = sortByOrder(timezoneList)

			props.wrapperSetTimezoneList(sorted)
			updateCustomUrl();
		}
	}

	// moves the timezone item down
	const moveDown =(timezone)=>{
		const id = timezone.id;
		const getOrder = timezone.order;
		if(getOrder < timezoneList.length-1){
			const moveDown = getOrder + 1;
			timezoneList.map(item => {
				if(item.order === moveDown && item.id !== id){
					item.order = item.order - 1;
				}
				else if(item.id === id){
					item.order = moveDown
				}
			})
			const sorted = sortByOrder(timezoneList)
			props.wrapperSetTimezoneList(sorted)
			updateCustomUrl();
		}
	}

	// removes all items
	const clearTimezones = ()=>{
		let emptyTimezone = [];
		props.wrapperSetTimezoneList(emptyTimezone);
		setCustomUrl("")
	}

	// resets the timezone to the original state
	const resetTimezones = ()=>{
		setReset(true);
		setTimezoneList( props.wrapperResetTimezoneList())
		
	}

	// builds the selected value using the text + abbr + dstAbbr
	const retrieveSelectedValue =(timezone, id )=>{
		
		const getTimezoneItem = timezoneList.filter(x=>{
			if(x.id === id){
				return x;
			}
		});
		
		const getTimezone = getTimezoneItem[0].timezone;
		
		if(getTimezone){
			return assembleTimezoneName(getTimezone.text, getTimezone.abbr, getTimezone.dstAbbr);
		}
		return "";
		
	}

	return (
		<div className="modal is-active">
			<div className="modal-background" onClick={props.closeGeneratorModalStateSetter} />
				<div className="modal-card" style={{width:"75%", height:"80%", align: "center"}}>
					<header className="modal-card-head">
					<div style={{fontSize: "2rem"}} className="modal-card-title">URL Generator</div>
				
					<button className="delete" onClick={props.closeGeneratorModalStateSetter} />
					</header>
					<section className="modal-card-body">
						<pre>
							<code style={{fontSize: "1rem"}}>{customUrl}</code>
						</pre>
						<div className="tooltip">
							<span className="tooltiptext">Add</span>
							<button  onClick={()=>{addBlank()}} className="button-icon">
								<figure className="image" style={{height: "2rem", width: "2rem"}}>
									<img className="is-rounded" src={"/files/icons/add.png"}/>
								</figure>
							</button>
						</div>
						<div className="tooltip">
							<span className="tooltiptext">Clear</span>
							<button  onClick={()=>{clearTimezones()}} className="button-icon">
								<figure className="image" style={{height: "2rem", width: "2rem"}}>
									<img className="is-rounded" src={"/files/icons/clear.png"}/>
								</figure>
							</button>
						</div>
						<div className="tooltip">
							<span className="tooltiptext">Reset</span>
							<button className="button-icon" onClick={()=>{
								resetTimezones()
								updateCustomUrl()}} >
								<figure className="image" style={{height: "2rem", width: "2rem"}}>
									<img className="is-rounded" src={"/files/icons/reset.png"}/>
								</figure>
							</button>
						</div>
						<div className="tooltip">
							<span className="tooltiptext">Copy</span>
							<button className="button-icon" onClick={()=>copyToClipboard(customUrl)} >
								<figure className="image" style={{height: "2rem", width: "2rem"}}>
									<img className="is-rounded" src={"/files/icons/copy.png"}/>
								</figure>
							</button>
						</div>

						{timezoneList.map(item=>{
							let selectedValue = retrieveSelectedValue(item.timezone, item.id)
							return(
							<div key={item.id} className="field is-horizontal" >
								
								<SearchableDropdown handleChange={(event) => handleDropDownChange(item, event)} 
									options={allTimezones} keySelected={item.key} 
									label="text" id="key" 
									reset={reset}
									wrapperSetReset={wrapperSetReset}
									selectedVal={selectedValue}/> 

								<input className="input" style={{ marginLeft: "0.5rem", marginRight: "0.5rem", width: "100%" }} onChange={(event)=>{customNameOnChange(item.id, event)}} value={item.name} type="text" placeholder="Name" ></input>
							
								<div className="tooltip">
									<span className="tooltiptext">Move Up</span>
									<button className="button-icon" onClick={()=>moveUp(item)} >
										<figure  className="image" style={{height: "2rem", width: "2rem"}}>
											<img className="is-rounded" src={"/files/icons/ic_shortcut_arrow_upward.png"}/>
										</figure>
									</button>
								</div>
							
								<div className="tooltip" >
									<span className="tooltiptext">Move Down</span>
									<button className="button-icon" onClick={()=>moveDown(item)} >
										<figure  className="image" style={{height: "2rem", width: "2rem"}}>
											<img className="is-rounded" src={"/files/icons/ic_shortcut_arrow_downward.png"}/>
										</figure>
									</button>
								</div>
								<div className="tooltip">
									<span className="tooltiptext">Remove</span>
									<button className="button-icon"  onClick={()=>removeTimezone(item.id)} >
										<figure className="image" style={{height: "2rem", width: "2rem"}}>
											<img className="is-rounded" src={"/files/icons/ic_shortcut_close.png"}/>
										</figure>
									</button>
								</div>
									
							</div>
						)
						})}

					</section>

			</div>
		</div>

	)

}

export default TimezoneUrlGeneratorModal;   
