import React, { useEffect, useRef, useState } from "react";

export default function useAssembleTimezoneName() {


   const assembleTimezoneName = (text, abbr, dstAbbr)=>{
    return `${text} (${abbr}${dstAbbr ? ` / ${dstAbbr}` : ""})`  
  }

  return assembleTimezoneName;

}