import React, { useEffect, useState } from "react";
import { GetSection } from "./functions/getSection";
const ZoneDate = props => {

  let section = GetSection(props.zoneWidth);
  let position = props.offset * (-section*4) - (96 * section)
  return (
    <>  
        <div className="zone-date default" style={{left: position}}>
            <span className="zone-date-text" >{props.date}</span>
        </div>
    </>

  );
};

export default ZoneDate;
