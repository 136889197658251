import React, { useEffect, useRef, useState } from "react";

export default function useCopyToClipboard() {


  const copy = async (text) => {
   if (typeof navigator !== 'undefined') {
    try {
        await navigator.clipboard.writeText(text);
      
      } catch (e) {
        throw e;
      }
    }
  }
  // 👇 We want the result as a tuple
  return copy

}